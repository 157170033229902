<!-- eslint-disable vue/no-unused-vars -->
<template>
    <div class="course-management-wrap">
        <div class="course-management-main">
            <div class="main-form">
                <div class="main-form-first flex x-between y-center f18">
                    <div>筛选查询</div>
                    <wj-button type="primary" @click="handleClickToSearch">查询结果</wj-button>
                </div>
                <div class="main-form-second">
                    <!-- 表单 -->
                    <wj-form ref="filterFormRef" :inline="true" label-width="80px">
                        <wj-form-item label="所属学校" placeholder="模糊搜索【广州】">
                            <wj-input
                                autosize
                                placeholder="模糊搜索【广州】"
                                v-model="schoolListParams.school"
                            ></wj-input>
                        </wj-form-item>
                        <wj-form-item label="学校账号" placeholder="请输入">
                            <wj-input
                                placeholder="请输入"
                                v-model="schoolListParams.username"
                            ></wj-input>
                        </wj-form-item>
                    </wj-form>
                </div>
            </div>
            <div class="main-table">
                <div class="main-table-first flex x-between y-center f18">
                    <div>数据列表</div>
                    <wj-button type="primary" @click="handleClickToAdd">添加</wj-button>
                </div>
                <div class="main-table-second">
                    <!-- 表格 -->
                    <wj-table :data="tableData" style="width: 100%">
                        <wj-table-column prop="school" label="所属学校"></wj-table-column>
                        <wj-table-column label="封面">
                            <template slot-scope="scope" v-if="imgList[scope.$index]">
                                <wj-image :src="imgList[scope.$index].content"></wj-image>
                            </template>
                        </wj-table-column>
                        <wj-table-column prop="username" label="学校账号"> </wj-table-column>
                        <wj-table-column prop="maxActivities" label="权限数量"> </wj-table-column>
                        <wj-table-column prop="createdAt" label="添加时间"> </wj-table-column>
                        <wj-table-column label="是否启用" prop="enabled">
                            <template slot-scope="scope">
                                <wj-switch
                                    :value="scope.row.enabled"
                                    @change="handleChangeSwitch(scope.row, scope.$index)"
                                    active-color="#3a76f6"
                                >
                                </wj-switch>
                            </template>
                        </wj-table-column>
                        <wj-table-column label="操作">
                            <template slot-scope="scope">
                                <!-- <wj-button type="text" size="small">查看</wj-button> -->
                                <wj-link
                                    :underline="false"
                                    type="danger"
                                    @click="deleteUser(scope.row)"
                                    >删除</wj-link
                                >
                                <wj-link
                                    :underline="false"
                                    type="primary"
                                    style="margin-left: 20px"
                                    @click="editUser(scope.row)"
                                    >编辑</wj-link
                                >
                            </template>
                        </wj-table-column>
                    </wj-table>
                </div>
                <div class="main-table-footer">
                    <wj-pagination
                        @current-change="handleCurrentChange"
                        :current-page="schoolListParams.current + 1"
                        :page-size="schoolListParams.size"
                        layout="prev, pager, next, jumper"
                        :total="total"
                        :hide-on-single-page="true"
                    >
                    </wj-pagination>
                </div>
            </div>
        </div>
        <!-- 新增/编辑 -->
        <wj-dialog
            :visible.sync="showUserDialog"
            width="630px"
            :title="dialogType == 1 ? '添加用户' : '编辑用户'"
            :show-close="true"
            custom-class="add-container"
            :close-on-click-modal="false"
        >
            <wj-form ref="userFormRef" :model="userFormRef" label-width="80px" :rules="rules">
                <wj-form-item label="用户账号">
                    <wj-input v-model="userFormRef.username" disabled></wj-input>
                    <span class="brief" v-if="dialogType == 1">随机生成6位数字</span>
                </wj-form-item>
                <wj-form-item label="密码" prop="password" class="password-item">
                    <wj-input v-model="userFormRef.password" :disabled="dialogType == 1"></wj-input>
                    <span class="brief" v-if="dialogType == 1">随机生成6位数字</span>
                    <span class="brief" v-else>输入后将修改原始密码，空白则保留原始密码</span>
                </wj-form-item>
                <!-- <wj-form-item label="确认密码">
                    <wj-input v-model="userFormRef.password"></wj-input>
                </wj-form-item> -->
                <wj-form-item label="学校名称" prop="school">
                    <wj-input v-model="userFormRef.school"></wj-input>
                </wj-form-item>
                <wj-form-item label="封面" class="upload-info" prop="cover">
                    <!-- <wj-input v-model="userFormRef.cover"></wj-input> -->
                    <img-upload v-model="userFormRef.cover" @success="uploadSuccess"></img-upload>
                    <span class="brief">建议尺寸：310*180</span>
                </wj-form-item>
                <wj-form-item label="权限数量" prop="maxActivities">
                    <wj-input v-model="userFormRef.maxActivities"></wj-input>
                </wj-form-item>
                <wj-form-item label="账号状态">
                    <!-- <wj-switch
                        v-model="userFormRef.enabled"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    >
                    </wj-switch> -->
                    <wj-checkbox v-model="userFormRef.enabled">启用</wj-checkbox>
                </wj-form-item>
                <wj-form-item>
                    <div style="float: right">
                        <wj-button @click="showUserDialog = false">取消</wj-button>
                        <wj-button v-if="dialogType == 1" type="primary" @click="doCreateUser"
                            >确定</wj-button
                        >
                        <wj-button v-if="dialogType == 2" type="primary" @click="doEditUser"
                            >确定</wj-button
                        >
                    </div>
                </wj-form-item>
            </wj-form>
        </wj-dialog>
    </div>
</template>

<script>
import {
    Button,
    Dialog,
    Form,
    FormItem,
    Input,
    Switch,
    Table,
    TableColumn,
    Checkbox,
    Image,
    Link,
    Pagination,
} from '@wenjuan/ui';
import {
    httpGetEducateeUserList,
    httpPostEducateeUser,
    httpPatchEnabled,
    httpPutEditUser,
    httpDeleteUser,
} from '@/http/user/index.js';
import ImgUpload from '@/components/upload/index.vue';
import { httpGetImageList } from '@/http/public/index.js';
export default {
    components: {
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-input': Input,
        'wj-button': Button,
        'wj-table': Table,
        'wj-table-column': TableColumn,
        'wj-switch': Switch,
        'wj-dialog': Dialog,
        'wj-checkbox': Checkbox,
        'wj-image': Image,
        'wj-link': Link,
        'wj-pagination': Pagination,
        ImgUpload,
    },
    data() {
        let validatePass = (rule, value, callback) => {
            let reg = /^\d*$/;
            if ((value.length < 6 || value.length > 8) && value != '') {
                callback(new Error('新密码为6-8位'));
            } else if (!reg.test(value)) {
                callback(new Error('新密码请输入数字'));
            } else {
                callback();
            }
        };

        return {
            filterFormRef: {
                schoolName: '',
                schoolAccount: '',
            },
            tableData: [],
            schoolListParams: {
                current: 0,
                size: 5,
                school: '', //学校名
                username: '', //学校名
            },
            total: 0,
            showUserDialog: false,
            userFormRef: {
                educateeId: '',
                school: '',
                username: '',
                password: '',
                cover: '',
                maxActivities: 0,
                enabled: true,
            },
            dialogType: 1, //1：新增 2：编辑
            imgList: [],
            rules: {
                school: [{ required: true, message: '请输入学校名称', trigger: 'blur' }],
                cover: [{ required: true, message: '请上传封面', trigger: 'blur' }],
                maxActivities: [{ required: true, message: '请输入权限数量', trigger: 'blur' }],
                password: [{ validator: validatePass, trigger: 'blur' }],
            },
        };
    },
    created() {
        this.getUserList();
    },
    methods: {
        async getUserList() {
            let res = await httpGetEducateeUserList(this.schoolListParams);
            let tableData = res.data.data;
            let imgList = tableData.map((res) => {
                return res.coverImageId;
            });
            let total = Number(res.data.total);
            if (imgList.length) {
                let imgItem = await this.getImageList(imgList);
                this.imgList = imgItem;
            }
            this.tableData = tableData;
            this.total = total;
        },
        handleClickToSearch() {
            this.schoolListParams.current = 0;
            this.getUserList();
        },
        // 新增
        handleClickToAdd() {
            this.userFormRef = {
                school: '',
                username: '',
                password: '',
                cover: '',
                maxActivities: 0,
                enabled: true,
            };
            this.dialogType = 1;
            this.userFormRef.username = this.getRandom();
            this.userFormRef.password = this.getRandom();
            this.showUserDialog = true;
        },
        //编辑
        async editUser(row) {
            this.dialogType = 2;

            let {
                educateeId,
                school,
                username,
                coverImageId,
                maxActivities,
                enabled,
                password = '',
            } = row;

            let image = await this.getImageList([coverImageId]);

            this.userFormRef = {
                educateeId,
                school,
                username,
                cover: image[0].content,
                maxActivities,
                enabled,
                password,
            };
            this.showUserDialog = true;
        },
        //删除用户
        deleteUser(row) {
            this.$confirm('是否确认删除该用户？', '删除用户', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    let { educateeId } = row;
                    httpDeleteUser(educateeId).then(() => {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                            duration: 2000,
                        });
                        if (this.tableData == 1 && this.schoolListParams.current > 0) {
                            this.schoolListParams.current--;
                        }
                        this.getUserList();
                    });
                })
                .catch(() => {});
        },
        //新增用户
        doCreateUser() {
            let params = {
                ...this.userFormRef,
            };
            delete params.educateeId;
            this.$refs['userFormRef'].validate((valid) => {
                if (valid) {
                    httpPostEducateeUser(params).then(() => {
                        this.getUserList();
                        this.showUserDialog = false;
                        this.$message({
                            message: '新增成功',
                            type: 'success',
                            duration: 2000,
                        });
                    });
                }
            });
        },
        //修改用户
        doEditUser() {
            let params = {
                ...this.userFormRef,
            };
            let educateeId = params.educateeId;
            delete params.educateeId;
            this.$refs['userFormRef'].validate((valid) => {
                if (valid) {
                    httpPutEditUser(educateeId, params).then(() => {
                        this.getUserList();
                        this.showUserDialog = false;
                        this.$message({
                            type: 'success',
                            message: '修改成功',
                            duration: 2000,
                        });
                    });
                }
            });
        },
        // 改变激活状态
        handleChangeSwitch(row, index) {
            let params = {
                educateeId: row.educateeId,
                enabled: row.enabled ? false : true,
            };
            httpPatchEnabled(params).then(() => {
                this.$message({
                    type: 'success',
                    message: '操作成功',
                    duration: 2000,
                });
                this.tableData[index].enabled = row.enabled ? false : true;
            });
        },

        uploadSuccess() {},
        getRandom() {
            var code = '' + (parseInt(Math.random() * 1000000) + 1000000);
            return code.substring(1, 7);
        },
        getImageList(list) {
            return new Promise((resolve, reject) => {
                httpGetImageList(list)
                    .then((res) => {
                        // console.log('图片列表', res);
                        // this.imgList = res.data.data;
                        resolve(res.data.data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        //跳转页面
        handleCurrentChange(val) {
            this.schoolListParams.current = val - 1;
            this.getUserList();
        },
    },
};
</script>

<style lang="scss" scoped>
.course-management-wrap {
    // background-color: #f3f5f7;
    height: 100%;
    .course-management-main {
        width: 1330px;
        margin: 0 auto;
        height: 100%;
        margin-top: 40px;
        .main-form {
            height: 175px;
            box-sizing: border-box;
            margin: 0 0 20px;
            background-color: #fff;
            padding: 0 40px 0 36px;
            .main-form-first {
                height: 95px;
            }
            .main-form-second {
                .wj-input {
                    width: 350px;
                }
            }
        }
        .main-table {
            padding: 0 40px 0 36px;
            background-color: #fff;
            .main-table-first {
                height: 95px;
            }
            .main-table-footer {
                padding: 20px 0 40px;
                .wj-pagination {
                    text-align: center;
                }
            }
        }
    }
}
.add-container {
    .wj-input {
        width: 304px;
    }
    .password-item {
        ::v-deep .wj-form-item__content {
            display: flex;
            align-items: center;
        }
    }
    .brief {
        color: #e84955;
        margin-left: 10px;
        display: inline-block;
        width: 160px;
        line-height: 15px;
    }
    .upload-info {
        ::v-deep .wj-form-item__content {
            display: flex;
            align-items: center;
        }
        .brief {
            margin-left: 10px;
            color: #aaaaaa;
            font-size: 12px;
        }
    }
}
</style>
